<template>
  <section class="min-w1600">
    <!-- <div class="tab-wrap">
      <button type="button" class="tab-item" :class="{ active: reqData.cashStatus === '0' }" @click="changeListType" value="0">{{ $t("button.reDeposit") }}</button>
      <button type="button" class="tab-item" :class="{ active: reqData.cashStatus === '2' }" @click="changeListType" value="2">대기목록</button>
      <button type="button" class="tab-item" :class="{ active: reqData.cashStatus === '1' }" @click="changeListType" value="1">승인목록</button>
      <button type="button" class="tab-item" :class="{ active: reqData.cashStatus === '-1' }" @click="changeListType" value="-1">거절목록</button>
      <button type="button" class="tab-item" :class="{ active: reqData.cashStatus === '' }" @click="changeListType" value="">전체보기</button>
    </div> -->
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-check searchbar-content mr-5">
           <div class="title"><span>{{ $t('searchArea.date') }}</span></div>
           <div class="fx">
             <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" @setEndDate="setEndDate" :startDefault="startDefault" :endDefault="endDefault" :configs="dateConfigs" />
             <button class="btn-search ml-5" type="button" @click="pageSeach">
               <i class="fas fa-search"></i>
             </button>
           </div>
         </div>
      </div>
      <memo></memo>
    </div>
    <div class="main-contents-wrapper">
      <h4 class="tname">{{ $t('common.flowNotice') }}</h4>
      <div class="table-wrapper">
        <table class="mainTable">
          <colgroup>
            <col width="65%">
            <col width="10%">
            <col width="15%">
            <col width="10%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('table.head.content') }}</th>
              <th>{{ $t('table.head.writedate') }}</th>
              <th>{{ $t('table.head.admin') }}</th>
              <th>{{ $t('user.option') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
             <td class="contentLimit tw-40">
               <span v-if="!isOpenFlow" style="white-space: normal;">{{ flowDetail.content}}</span>
               <textarea v-if="isOpenFlow" style="min-height: auto; padding: 5px 10px; height: 30px;" v-model="flowDetail.content"></textarea>
             </td>
             <td class="roboto">{{ flowDetail.regDate }}</td>
             <td>{{ flowDetail.regId }}</td>
             <td style="width: 10%">
               <div class="status-change-btn-wrap">
                 <button v-if="!isOpenFlow" class="btn-innerTable btn-status-change wait" type="button" @click="btnToggle()">{{ $t('button.modify') }}</button>
                 <button v-if="isOpenFlow" class="btn-innerTable btn-status-change wait" type="button" @click="onSaveFlow()">{{ $t('button.save') }}</button>
                 <button class="btn-innerTable btn-status-change wait" type="button">{{ $t('button.del') }}</button>
               </div>
             </td>
            </tr>
            <!--template v-for="(item, idx) in tableData" :key="item.boardIdx">
              <tr>
                <td class="contentLimit tw-40">
                  <span v-if="!item.isActive" style="white-space: normal;"></span>
                  <textarea v-if="item.isActive" style="min-height: auto; padding: 5px 10px; height: 30px;"></textarea>
                </td>
                <td>{{ item.regDate }}</td>
                <td>{{ item.regId }}</td>
                <td style="width: 10%">
                  <div class="status-change-btn-wrap">
                    <button v-if="!item.isActive" class="btn-innerTable btn-status-change wait" type="button" @click="btnToggle(item)">{{ $t('button.modify') }}</button>
                    <button v-if="item.isActive" class="btn-innerTable btn-status-change wait" type="button" @click="btnToggle(item)">{{ $t('button.save') }}</button>
                    <button class="btn-innerTable btn-status-change refuse" type="button">{{ $t('button.del') }}</button>
                  </div>
                </td>
              </tr>
            </template-->
          </tbody>
        </table>
      </div>
      <article class="total-info-container mt-10">
         <h4 class="tname mb-0">{{ $t('common.normalNotice') }}</h4>
         <div class="fx-col-sbw gap-25">
            <div class="total-info-wrap">
              <div class="total-info-item">
                <img :src="require('@/assets/img/searchicon.png')" />
                <i class="fas fa-search"></i>
                <span>{{ $t('searchArea.schResult') }}</span>
              </div>
              <div class="total-info-item">
                <span class="title">{{ $t(`totalInfo.tatal_list_count`) }}</span> : <span class="value">{{ pageInfo.tatal_list_count }}</span>
              </div>
              <div class="total-info-item">
                <span class="title">{{ $t(`totalInfo.page`) }}</span> : <span class="value">{{ pageInfo.page }}</span>
              </div>
              <div class="total-info-item">
                <span class="title">{{ $t(`totalInfo.tatal_page_count`) }}</span> : <span class="value">{{ pageInfo.tatal_page_count }}</span>
              </div>
            </div>
            <div class="total-info-wrap">
              <!-- <button type="button" class="btn-layout btn-grey mr-5">테스트 메세지 추가</button> -->
              <button type="button" class="btn-layout btn-grey" @click="detailModalOpen('c')">{{ $t('common.addNotice') }}</button>
            </div>
         </div>
      </article>

      <div class="table-wrapper">
        <table class="mainTable">
          <table-head :headInfo="headInfo" />
          <tbody>
            <template v-for="(item, idx) in tableData" :key="item.boardIdx">
              <tr>
                <td class="roboto">{{ Number(pageInfo.tatal_list_count) - idx }}</td>
                <td class="contentLimit tw-20">{{ item.title }}</td>
                <td class="contentLimit tw-40"><div class="txt">{{ item.content }}</div></td>
                <td class="roboto">{{ item.regDate }}</td>
                <td>
                  <div class="toggle-wrap">
                    <label class="toggle-text default on"></label>
                    <input type="checkbox" class="set-switch" v-model="item.isView" @click="onChangeNoticeView(item, 'isView')"/>
                  </div>
                </td>
                <td>
                  {{ item.regId }}
                </td>
                <td style="width: 15%">
                  <div class="status-change-btn-wrap">
                    <button class="btn-innerTable btn-status-change wait" type="button" @click="onChangeOrder(item)">{{ $t('searchArea.toTop') }}</button>
                    <button class="btn-innerTable btn-status-change wait" type="button" @click="getContentDetail(item.boardIdx)">{{ $t('button.modify') }}</button>
                    <button class="btn-innerTable btn-status-change wait" type="button" @click="deleteContent(item.boardIdx)">{{ $t('button.del') }}</button>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="tableData.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />

    <div class="modal-wrapper" v-if="updModalActive">
      <div class="modal-wrap">
        <article>
          <div class="sub-title">
            <h3 v-if="modalType === 'u'">{{ $t('table.head.modify') }}</h3>
            <h3 v-if="modalType === 'c'">{{ $t('button.add') }}</h3>
          </div>
          <div class="page-content">
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.qTitle2') }}</div>
                <div class="item-content">
                  <input type="text" v-model="boardDetail.title" />
                </div>
              </div>
            </div>
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.content') }}</div>
                <div class="item-content"><textarea v-model="boardDetail.content"></textarea></div>
              </div>
            </div>
            <!-- <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">우선 순위</div>
                <div class="item-content">
                  <input type="text" value="1" />
                </div>
              </div>
            </div> -->
            <div class="modal-btns">
              <a @click="updateBoard(boardDetail.boardIdx)" v-if="modalType === 'u'">{{ $t('button.save') }}</a>
              <a @click="saveBoard(boardDetail.boardIdx)" v-if="modalType === 'c'">{{ $t('button.save') }}</a>
              <a @click="modalClose()">{{ $t('button.close') }}</a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import lodash from 'lodash'
import TableHead from '@/components/main/table/Head.vue'
import DateSelector from '@/components/common/DateSelector'
import Pagination from '@/components/common/Pagination'
import { getSiteData } from '@/libs/auth-helper'
import { boardList, boardDetail, boardSave } from '@/api/member.js'
import { replaceDateT, numberWithCommas, getDateStr } from '@/libs/utils.js'
import { noticeOrderChange, noticeViewYn, saveFlowBoard } from '@/api/board'
import Memo from '@/components/common/memo'
export default {
  name: 'BoardQna',
  components: {
    TableHead,
    Pagination,
    DateSelector,
    Memo
  },
  data () {
    return {
      isActive: false,
      tableName: this.$route.name,
      headInfo: {
        fstColumn: false,
        dataList: ['index', 'qTitle2', 'content', 'writedate', 'visible', 'admin', 'option']
      },
      reqData: {
        page: 1,
        count_per_list: 30,
        boardType: 'nt',
        startDate: '',
        endDate: '',
        searchValue: ''
      },
      modalType: '',
      dateConfigs: {
        enableTime: false,
        dateFormat: 'Y-m-d'
      },
      startDefault: '',
      endDefault: '',
      tableData: [],
      pageInfo: {
        tatal_list_count: 0,
        tatal_page_count: 0,
        page: 0
      },
      updModalActive: false,
      boardType: 'nt',
      boardDetail: {},
      flowDetail: {
        content: ''
      },
      isOpenFlow: false
    }
  },
  methods: {
    onChangeNoticeView (item) {
      item.isView = !item.isView

      const params = {
        boardIdx: item.boardIdx,
        viewYn: item.isView ? 'Y' : 'N'
      }
      noticeViewYn(params).then(res => {

      })
    },
    async onSaveFlow () {
      const params = {
        content: this.flowDetail.content
      }

      await saveFlowBoard(params).then(res => {
        if (res.resultCode === '0') {
          alert('흐르는 공지가 정상적으로 저장되었습니다.')
          this.isOpenFlow = false
        }
      })
    },
    btnToggle () {
      this.isOpenFlow = !this.isOpenFlow
    },
    onChangeOrder (item) {
      const params = {
        boardIdx: item.boardIdx
      }
      noticeOrderChange(params).then(res => {
        if (res.resultCode === '0') {
          alert('정상적으로 변경되었습니다.')
          this.setTableData(1)
        }
      })
    },
    setStartDate (date) {
      // console.log(date);
      let _date = ''
      if (!date) {
        _date = new Date()
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd')
    },
    setEndDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
      } else {
        _date = date[0]
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd')
    },
    goSearch (page) {
      this.setTableData(page)
    },
    pageSeach () {
      this.setTableData()
    },
    async deleteContent (boardIdx) {
      const trigger = confirm('해당 게시물을 삭제하시겠습니까?')
      if (!trigger) {
        return
      }
      const req = {
        boardIdx,
        delYn: 'Y'
      }
      const boardType = this.boardType
      const res = await boardSave(boardType, req)
      // console.log(res);
      if (res.resultCode === '0') {
        alert('게시물 삭제 완료')
        this.setTableData()
      } else {
        alert('게시물 삭제 실패, 다시 시도해주세요.')
      }
    },
    async updateBoard (boardIdx) {
      const trigger = confirm('해당 게시물을 수정하시겠습니까?')
      if (!trigger) {
        return
      }
      const req = {
        boardIdx,
        title: this.boardDetail.title,
        content: this.boardDetail.content
      }
      const res = await boardSave(this.boardType, req)
      // console.log(res);
      if (res.resultCode === '0') {
        alert('게시물 수정 완료')
        this.setTableData()
        this.modalClose()
      } else {
        alert('게시물 수정 실패, 다시 시도해주세요.')
      }
    },
    async saveBoard () {
      const siteInfo = this.getSiteInfo()
      // console.log(siteInfo);
      const trigger = confirm('해당 게시물을 등록 하시겠습니까?')
      if (!trigger) {
        return
      }
      const req = {
        memId: siteInfo.siteId,
        boardType: 'nt',
        category: 'normal',
        title: this.boardDetail.title,
        content: this.boardDetail.content
      }
      const res = await boardSave(this.boardType, req)
      // console.log(res);
      if (res.resultCode === '0') {
        alert('게시물 저장 완료')
        this.setTableData()
        this.modalClose()
      } else {
        alert('게시물 등록 실패, 다시 시도해주세요.')
      }
    },
    getSiteInfo () {
      const siteInfoCookie = getSiteData()
      if (siteInfoCookie) {
        const siteInfo = JSON.parse(siteInfoCookie)
        return siteInfo
      }
    },
    async createBoard () {
      const siteInfo = this.getSiteInfo()
      // console.log(siteInfo);
      const trigger = confirm('해당 게시물을 등록 하시겠습니까?')
      if (!trigger) {
        return
      }
      const req = {
        memId: siteInfo.siteId,
        title: this.boardDetail.title,
        content: this.boardDetail.content
      }
      const res = await boardSave(this.boardType, req)
      // console.log(res);
      if (res.resultCode === '0') {
        alert('게시물 등록 완료')
        this.setTableData()
        this.modalClose()
      } else {
        alert('게시물 등록 실패, 다시 시도해주세요.')
      }
    },
    modalClose () {
      this.updModalActive = false
      this.boardDetail = {}
    },
    detailModalOpen (type) {
      this.modalType = type
      if (type === 'c') {
        this.boardDetail = {}
      }
      this.updModalActive = true
    },
    async getContentDetail (boardIdx) {
      const req = {
        boardIdx
      }
      const res = await boardDetail(this.boardType, req)
      this.boardDetail = res.data.board
      // console.log(this.boardDetail);
      this.detailModalOpen('u')
    },
    async setTableData (page) {
      this.emitter.emit('Loading', true)
      if (page) {
        this.reqData.page = page
      } else {
        this.reqData.page = 1
      }
      const req = lodash.cloneDeep(this.reqData)
      const boardType = this.boardType
      const res = await boardList(boardType, req)
      if (res.data.flowBoard) {
        this.flowDetail = res.data.flowBoard
      }
      if (res.data.pageInfo) {
        this.pageInfo = res.data.pageInfo
        this.pageInfo.tatal_list_count = numberWithCommas(this.pageInfo.tatal_list_count)
        this.pageInfo.tatal_page_count = numberWithCommas(Number(this.pageInfo.tatal_page_count) + 1)
      }
      this.tableData = res.data.list
      this.tableData.forEach(item => {
        if (item.viewYn === 'Y') {
          item.isView = true
        } else {
          item.isView = false
        }
      })
      this.emitter.emit('Loading', false)
    }
  },

  async created () {
    await this.setTableData(1)
  }
}
</script>

<style scoped>
.tname {margin: 20px auto 20px 0;font-size: 20px;}
.sub-title {
   color: #646464;
   font-size: 13px;
   padding-bottom: 11px;
   border-bottom: 2px solid #959595;
   display: flex;
   align-items: center;
   justify-content: space-between;
}
textarea {
  width: 100%;
  min-height: 134px;
  border: 1px solid #aaa;
  resize: none;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: #fafafa;
  padding: 10px;
}
.pt50 {
  padding-top: 50px;
}
.ml10 {
  margin-left: 10px;
}
.modal-wrapper {
  display: flex;
  font-size: 16px;
}
th:nth-child(2) {
  width: 20%;
}
th:nth-child(3) {
  width: 50%;
}
.contentLimit {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}
.toggle-wrap {
  justify-content: center;
}
</style>
